/* eslint-disable react/jsx-no-comment-textnodes */
import * as React from "react";
import Layout from "../../Layout/MasterLayout/MasterLayout";
import CardValores from "../../components/CardValores/CardValores";
import CardConteudo from "../../components/CardConteudo/CardConteudo";

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

import imgMenina from "../../assets/images/contraturno/atividades-extracurriculares/atividades-extracurriculares.png";
import imgAqualand from "../../assets/images/contraturno/atividades-extracurriculares/aqualand.png";
import imgVivaLaVida from "../../assets/images/contraturno/atividades-extracurriculares/vivalavida.png";
import imgAltasaventuras from "../../assets/images/contraturno/atividades-extracurriculares/altasaventuras.png";
import imgDivertidamentes from "../../assets/images/contraturno/atividades-extracurriculares/divertidamentes.png";
import imgTaDandoOnda from "../../assets/images/contraturno/atividades-extracurriculares/tadandoonda.png";
import SEO from "../../components/SEO/SEO";

import '../../components/css/images.css'

export default function AtividadesExtracurriculares() {
	let atividades = [
		{
			title: "UP! ALTAS AVENTURAS!",
			image: imgAltasaventuras,
			horario: "Segunda-feira, das 13h30 às 17h30",
			description:
				"e se a aventura fosse decidida por nós mesmos? Quantas possibilidades seriam descobertas com o trabalho em conjunto  dos participantes e o quão prazeroso seria aprender? Em UP! Altas Aventuras!, temos, como cenário, o ambiente natural  presente na Escola e, como protagonistas, nós mesmos, focando no contato direto com o meio ambiente, no conhecimento  sobre a natureza e seus desdobramentos e na nossa relação com o meio natural. Vem participar e, assim, cooperar para  um futuro melhor.",
			borderColor: "ensino",
			imgLeft: true,
		},
		{
			title: "TÁ DANDO ONDA!",
			image: imgTaDandoOnda,
			horario: "Terça-feira, das 13h30 às 17h30",
			description:
				"já pensou em aprender se divertindo? Nós pensamos! Nesta oficina, aprendizagem e diversão entrelaçam-se para proporcionar  aos estudantes a iniciação à prática da natação, focando nas diferentes modalidades do esporte (crawl, peito, borboleta e  costas). Além disso, a oficina recorre a atividades lúdicas diversas, relacionadas aos esportes e a jogos variados de  interação, ampliando os vínculos sociais dos estudantes. Além do complexo aquático, espaços como ginásios, campo de futebol  e pista de atletismo são explorados, promovendo experiências memoráveis!",
			borderColor: "ensino",
		},
		{
			title: "DIVERTIDAMENTES!",
			image: imgDivertidamentes,
			horario: "Quarta-feira, das 13h30 às 17h30",
			description:
				"e se a nossa Escola pudesse se transformar em um laboratório secreto de ciências? Ou em um universo robótico? Quem  sabe em um espaço de fotografia? Compartilhá-lo com seus amigos? Aqui, pode! Nesta oficina, habilidades cognitivas  são exploradas de formas criativas, sendo a imaginação a chave para o desenvolvimento das propostas práticas diversificadas,  envolvendo a investigação, o descobrimento, o registro e a ciência. Trabalhos em grupo favorecem a socialização e  contemplam desafios a serem solucionados em conjunto. Mentes criativas inovam! So... Let’s play!",
			borderColor: "ensino",
			imgLeft: true,
		},
		{
			title: "AQUALAND!",
			image: imgAqualand,
			horario: "Quinta-feira, das 13h30 às 17h30",
			description:
				"vamos mergulhar neste planeta aquático?! Por meio de atividades lúdicas, tanto individuais como coletivas,  que oportunizam aos mergulhadores momentos de diversão e socialização, são propostos momentos nos quais  habilidades e competências motoras são trabalhadas, além de momentos significativos acerca de estímulos  cognitivos e sócio afetivos. Go and... TIBUM!",
			borderColor: "ensino",
		},
		{
			title: "VIVA LA VIDA!",
			image: imgVivaLaVida,
			horario: "Sexta-feira, das 13h30 às 17h30",
			description:
				"um, dois, três... Ação! Nesta oficina, por meio de estratégias lúdicas e criativas, são realizadas diversas  vivências na música, no teatro, na dança e seus movimentos, no encanto do circo. Afinal, a arte é compartilhada  pelos protagonistas do grupo em ação. A oficina, ainda, contribui para o processo de criação/desinibição das  crianças em diferentes contextos, onde as várias linguagens (oral, escrita, digital, artística e corporal) emergem.  É hora de se expressar, de se movimentar, de agir!",
			borderColor: "ensino",
			imgLeft: true,
		},
	];

	return (
		<Layout>
			<SEO title="Atividades extracurriculares" lang="pt-br" />
			<MDBContainer>
				<MDBRow>
					<MDBCol col={12} md={6} className="order-1 order-sm-0 my-auto">
						<h1 className="pageTitle fgterciary-gradient d-none d-md-block">
							atividades extracurriculares
						</h1>
						<h1
							className="pageTitle fgterciary-gradient d-block d-md-none"
							style={{ fontSize: "3em" }}
						>
							atividades extracurriculares
						</h1>
						<h3 className="fgterciary-gradient">nosso propósito</h3>
						<p className="text-justify">
							São destinadas aos estudantes matriculados na Educação Infantil e
							nas Etapas Iniciais do Ensino Fundamental (1° e 2º ciclos). Elas
							ocorrem tanto no período da manhã como no da tarde e proporcionam
							aos estudantes o desenvolvimento pessoal, social, cultural,
							afetivo, lúdico, criativo e físico, utilizando, para tanto,
							diferentes espaços de aprendizagem.
						</p>
						<p className="text-justify">
							Nessa modalidade, os responsáveis podem escolher o número de dias
							da semana e as atividades das quais desejam que os estudantes
							participem. Aqui, brincar é sinônimo de explorar, de criar, de
							inovar, de ressignificar, de se divertir!
						</p>
						<p className="text-left">
							Matrículas abertas! As atividades curriculares iniciam em 15/02/2024. 
						</p>
					</MDBCol>
					<MDBCol
						col={12}
						md={6}
						className="pt-2 text-justify order-0 order-sm-1 imagemTopo"
					>
						<img src={imgMenina} className="img-fluid" />
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<h3 className="fgterciary-gradient">conheça as atividades</h3>
					</MDBCol>
				</MDBRow>
				{atividades ? (
					<>
						{atividades.map((atividade, index) => (
							<CardConteudo
								key={index}
								title={atividade.title}
								horario={atividade.horario}
								description={atividade.description}
								borderColor={atividade.borderColor}
								img={atividade.image}
								imgLeft={atividade.imgLeft}
							/>
						))}
					</>
				) : (
					<p>Em breve novas atividades extracurriculares.</p>
				)}
				<MDBRow className="pt-3">
					<MDBCol col={12} md={6}>
						<h3 className="fgterciary-gradient">
							para estudantes da Escola de Aplicação Feevale
						</h3>
						<p className="text-left">
							Se você já estuda na Instituição, a matrícula das atividades
							extracurriculares é feita de forma on-line, com a possibilidade de
							matrícula para almoço.
						</p>
						<p className="text-left">
							Matrículas abertas! As atividades curriculares iniciam em 15/02/2024.
						</p>
						<p className="text-left">
							1º PASSO: <a target="blank" href="https://portal.feevale.br/FrameHTML/web/app/Edu/PortalProcessoSeletivo/?c=1&ps=144#/es/inscricoeswizard/dados-basicos">Clique aqui </a> para realizar sua inscrição.
						</p>
						<p className="text-left">
							2º PASSO: Após a inscrição, o responsável financeiro deverá acessar o <a target="blank" href="https://portal.feevale.br/FrameHTML/web/app/edu/portalEducacional/login/"> Portal do Aluno </a> para seguir com o processo de seleção e contratação das atividades por meio da Matrícula Online.
						</p>
						<p className="text-left">
							3º PASSO: A finalização acontece no Portal do Aluno e o contrato é assinado no mesmo lugar. Após a assinatura eletrônica do contrato, o estudante poderá participar das aulas matriculadas.
						</p>
						<p className="text-left">
							<b>Importante:</b> Os dados informados na inscrição passarão por validação e caso sejam identificadas inconsistências, a matrícula poderá ser cancelada e o responsável será comunicado. 
						</p>
					</MDBCol>
					<MDBCol col={12} md={6}>
						<h3 className="fgterciary-gradient">para comunidade</h3>
						<p className="text-left">
							Estudantes de outras instituições que têm interesse em participar
							das atividades extracurriculares devem fazer sua matrícula
							presencialmente no Atendimento Feevale.
						</p>
						<p className="text-left">
							Matrículas abertas! As atividades curriculares iniciam em 15/02/2024. 
						</p>
						<p className="text-left">
							1º PASSO: Agendar com a coordenação pedagógica da escola via telefone 3584-7108.
						</p>
						<p className="text-left">
							2º PASSO: Após o bate-papo com a coordenação pedagógica <a target="blank" href="https://portal.feevale.br/FrameHTML/web/app/Edu/PortalProcessoSeletivo/?c=1&ps=145#/es/inscricoeswizard/dados-basicos"> clique aqui</a> Para efetivar a inscrição é necessário ter digitalizado os seguintes documentos:
							<ul style={{ listStyleType: "none" }}>
								<li>// uma foto 3x4 do estudante;</li>
								<li>// cópia da Certidão de Nascimento do estudante;</li>
								<li>
									// cópia da Carteira de Identidade (RG) do estudante e
									responsável;
								</li>
								<li>// cópia do CPF do responsável;</li>
								<li>
									// cópia do comprovante de residência com CEP (conta de luz ou
									telefone).
								</li>
							</ul>
						</p>
						<p className="text-left">
							3º PASSO: Após a inscrição e validação dos documentos enviados, o responsável receberá um e-mail com as orientações dos próximos passos para a matrícula. 
						</p>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<h3 className="fgterciary-gradient text-center pt-3 pb-2">
							valores*
						</h3>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<CardValores
						bgColor="bgterciary-gradient"
						amount="1 atividade"
						value="321,83"
						type={2}
					/>
					<CardValores
						bgColor="bgterciary-gradient"
						amount="2 atividades"
						value="566,42"
						type={2}
					/>
					<CardValores
						bgColor="bgterciary-gradient"
						amount="3 atividades"
						value="772,39"
						type={2}
					/>
					<CardValores
						bgColor="bgterciary-gradient"
						amount="4 atividades"
						value="901,12"
						type={2}
					/>
					<CardValores
						bgColor="bgterciary-gradient"
						amount="5 atividades"
						value="965,49"
						type={2}
					/>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<h3 className="fgterciary-gradient text-center pt-3 pb-2">
							almoço*
						</h3>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<CardValores
						bgColor="bgterciary-gradient"
						amount="por almoço"
						value="18,53"
						type={2}
					/>
					{/*
					<CardValores
						bgColor="bgterciary-gradient"
						amount="2 refeições"
						value="121,44"
						type={2}
					/>
					<CardValores
						bgColor="bgterciary-gradient"
						amount="3 refeições"
						value="182,16"
						type={2}
					/>
					<CardValores
						bgColor="bgterciary-gradient"
						amount="4 refeições"
						value="242,88"
						type={2}
					/>
					<CardValores
						bgColor="bgterciary-gradient"
						amount="5 refeições"
						value="303,60"
						type={2}
					/> */}
				</MDBRow>
				<MDBRow>
					<MDBCol className="pt-4">
						<p className="small font-italic">
							*Valores válidos para 2024. As matrículas contratadas em 2024 relativas ao ano de 2025 estão sujeitas ao reajuste de mensalidade conforme disposto na Lei nº 9.870 de 23/11/1999. Eventual diferença de valor será acrescida nas parcelas posteriores.
						</p>
						<br></br>
						<p>
							Para as contratações realizadas até o dia 15 do mês, será cobrado
							o valor integral da mensalidade. Das matrículas realizadas do dia
							11 ao dia 15, o valor será cobrado no mês subsequente, junto com o
							a mensalidade do mês seguinte. Para as contratações realizadas a
							partir do dia 16 do mês, o contratante pagará 50% do valor total
							da mensalidade do mês, juntamente com a mensalidade do mês
							seguinte. Para o restante dos meses, será cobrado o valor
							integral, conforme contratado.
						</p>
						<p>
							Para as contratações realizadas até o dia 10 de cada mês, os
							valores serão cobrados no respectivo mês.
						</p>
						<p>
							A realização das Atividades Extracurriculares está condicionada ao
							número mínimo de estudantes matriculados por turma.
						</p>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</Layout>
	);
}
